import riot from "riot";
import WidgetManager from "./widget-manager";

import "./page";
import "./core-tags";
import "./viewer.scss";
import Router from "../router";
import TimelineManager from "../animation/timeline-manager";
import PageTransition from "../animation/page-transition";
import TweenLite from "TweenLite";
import * as browser from "../utils/browser";
import Storyline from "../storyline";
import Datasources from "../datasources";
import WindowManager from "../utils/window-manager";
import raf from "../utils/raf";
import { onLiveEvent, offLiveEvent } from "../utils/dom";

window.utils = {
  browser
};

export default function(
  element: HTMLElement,
  opts: Object,
  disableRouter: boolean,
  disableAutoDeviceMode: boolean
) {
  if (disableRouter) Router.disable();
  // if (disableAutoDeviceMode) WindowManager.setDisabled(true);
  return riot.mount(element, "viewer", opts)[0];
}

const tmpl = `
        <widget each="{ key in fixed.children }" if="{isVisible(key)}"></widget>
        <page></page>
        <page></page>
    `,
  CENTER_VALUES = { x: 0, y: 0, opacity: 1, scale: 1 },
  EMPTY_PAGE = { opts: {} };

riot.tag("viewer", tmpl, "", 'class="{ opts.className }"', function(opts) {
  const self = this;
  window.view = this;
  /**
   * PUBLIC VARIABLES
   */
  self.pageKey = undefined;
  self.nextPageKey = undefined;
  self.mode = "desktop";
  self.scale = 1;
  self.viewport = [0, 0, window.innerWidth, window.innerHeight];
  self.visibility = {};
  self.widgets = {};
  self.actions = {};
  self.pages = {};
  self.menus = {};
  self.primaryMenu = {};
  self.timeline = new TimelineManager(
    onTransitionStart,
    onTransitionComplete,
    onTransitionReverseomplete
  );
  /**
   * PUBLIC FUNCTION
   */
  self.setDeviceMode = setDeviceMode;
  self.getDeviceMode = getDeviceMode;
  self.hasPage = hasPage;
  self.setPage = setPage;
  self.requestPageTransition = requestPageTransition;
  self.getPageTag = getPageTag;
  self.getActivePageKey = getActivePageKey;
  self.getPageTitle = getPageTitle;
  self.getPagePath = getPagePath;
  self.getPageCover = getPageCover;

  self.getMenu = getMenu;
  self.getMenus = getMenus;
  self.getData = getData;
  self.getDataModel = getDataModel;
  self.setDisabled = setDisabled;
  this.getWindowManager = getWindowManager;
  self.isVisible = isVisible;
  self.setRouterDisable = setRouterDisable;
  self.preloadPage = preloadPage;

  self.getScrollElement = getScrollElement;
  self.setScrollTop = setScrollTop;
  self.scrollToElement = WindowManager.scrollToElement;
  self.scrollToTop = WindowManager.scrollToTop;
  self.getScrollTop = getScrollTop;
  self.cleanupTransition = cleanupTransition;

  self.on("before-mount", onBeforeMount);
  self.on("mount", onMount);
  self.on("unmount", onUnmount);
  self.on("update", updateOpts);
  WindowManager.on("devicemode", updateDeviceMode);
  Router.on("change", onRouteChange);
  WindowManager.on("scroll", e => this.trigger("scroll", e));
  WidgetManager.update(self.opts.models);

  /**
   * PRIVATE VARIABELS
   */
  const storyline: Storyline = new Storyline(),
    datasources: Datasources = new Datasources();

  var toScrollTop,
    fromScrollTop,
    nextPageScrollTop = false,
    activeTransition,
    page,
    nextPage,
    detectElement,
    queueWidgetScroll,
    menus,
    primaryMenu;

  updateOpts();

  // var mediaQueryList = window.matchMedia('print');
  // mediaQueryList.addListener(mql => {
  //   setDisabled(mql.matches);
  // });

  function hasPage(pageKey = self.pageKey) {
    return page && page.key === pageKey;
  }

  function getPageTag(unusedRef: boolean) {
    const pages = self.tags.page;
    if (unusedRef && pages) {
      return pages[0] === page ? pages[1] : pages[0];
    }

    return page;
  }

  function setDisabled(disabled) {
    self.disabled = disabled;
    this.trigger("disabled", disabled);
    WindowManager.restrictToPage(disabled);
    WindowManager.setDisabled(disabled);
  }

  function setRouterDisable(disabled) {
    self.disableRouter = disabled;
  }

  function getWindowManager() {
    return WindowManager;
  }

  function getMenu(menuKey = null) {
    if (!menuKey) {
      return self.primaryMenu;
    } else {
      return self.menus[menuKey];
    }
  }

  function getMenus() {
    return self.menus;
  }

  function getDataModel(sourceKey, modelKey) {
    return datasources.getOpts(sourceKey, modelKey);
  }

  function getData(datasourceKey, path) {
    return datasources.get(datasourceKey, path);
  }

  function onBeforeMount() {
    WindowManager.start();
  }

  function onMount() {
    onLiveEvent("a", "click", onLinkClick);
    // Need timeout becase of riot issue https://github.com/riot/riot/issues/2409
    setTimeout(() => {
      // if (self.pageKey) page.upda;
      if (!self.pageKey) setPage(Router.getPageKeyByPath());
      // else if (page) page.update(getPageContent());
    });
  }

  function onUnmount() {
    offLiveEvent("click", onLinkClick);
    WindowManager.stop();
  }

  function getScrollElement() {
    return detectElement;
  }

  function updateOpts() {
    var opts = self.opts;

    Router.update(opts.urlMap);
    WidgetManager.update(opts.models);
    WindowManager.scaling = opts.scaling;
    Object.assign(self.actions, opts.actions);
    Object.assign(self.pages, opts.pages || {});

    Object.assign(self.menus, opts.menus || {});
    self.primaryMenu = opts.menus && opts.menus[opts.primaryMenu];
    self.shapes = opts.shapes || {};
    self.fixed = opts.fixed || {};
    self.visibility = self.fixed.visibility || {};

    if (opts.datas) {
      datasources.update(opts.datas, opts.datam);
    }

    storyline.update(opts.stories);

    if (page) page.update(getPageContent());
  }

  function onRouteChange(state) {
    self.timeline.clear();
    delete self.transition;
    setPage(state.pageKey);
  }

  function onLinkClick(e: MouseEvent, target: HTMLLinkElement) {
    const path = target.getAttribute("href") || "";
    if (!path) return;
    const isInternal = /(#|\/)/.test(path.charAt(0));
    if (window.ga) {
      const isEmail = /^mailto/i.test(path);
      const isPhone = /^tel/i.test(path);
      let eventCategory;

      if (isInternal) eventCategory = "Internal";
      else if (isEmail) eventCategory = "Email";
      else if (isPhone) eventCategory = "Phone";
      else eventCategory = "Outbound";

      window.ga("send", "event", {
        eventCategory: eventCategory + " Link",
        eventAction: "click",
        eventLabel: path,
        transport: "beacon"
      });
    }

    // If not internal link then return
    if (!isInternal) return;

    const [pagePath, widgetKey] = path.split("#");
    const pageKey = pagePath && Router.getPageKeyByPath(pagePath);

    // Prevent default when internal link and page key is found
    if (pageKey) e.preventDefault();

    // if not same as current page then chagne to page
    // else if widgetKey then scroll to widget
    if (pageKey && pageKey !== self.pageKey) {
      queueWidgetScroll = widgetKey;
      let tween = target.dataset.tween;

      if (tween) {
        try {
          tween = JSON.parse(tween);
          const transition = requestPageTransition(pageKey, tween);
          if (transition) transition.play();
        } catch (e) {
          setPage(pageKey);
        }
      } else {
        setPage(pageKey);
      }
    } else if (widgetKey) {
      WindowManager.scrollToElement(widgetKey);
    }
  }

  function setDeviceMode(devicemode) {
    WindowManager.setDeviceMode(devicemode);
  }

  function getDeviceMode() {
    return WindowManager.getDeviceMode();
  }

  function updateDeviceMode(mode) {
    self.trigger("devicemode", mode);
  }

  /**
   * Sets the WindowManagers scroll top position
   * @param {Number} scrollTop - px to scroll
   * @param {Boolean} excludeOffset - if true then storyline offset is not added to position
   */
  function setScrollTop(scrollTop: Number, smooth: Boolean) {
    WindowManager.setScrollTop(scrollTop + WindowManager.getPageTop(), smooth);
  }

  /**
   * Retuns the scroll position
   * @param {String?} pageKey - key of page to get scroll position of
   * @returns {Number} - last or current scroll position of page
   */
  function getScrollTop() {
    return WindowManager.getPageLocalScroll(self.pageKey);
  }

  /**
   * Returns the key of the currently active page
   * @returns {String} -key of currently active page
   */
  function getActivePageKey() {
    return self.pageKey;
  }

  /**
   * Returns the title of the given page
   * @param {String} pageKey - key of page to get title of
   * @returns{String} - title of page
   */
  function getPageTitle(pageKey = self.pageKey) {
    const page = self.pages[pageKey];
    return page && page.title ? page.title : "Untitled";
  }

  function getPageCover(pageKey = self.pageKey) {
    const page = self.pages[pageKey];
    return page && page.cover ? page.cover : "Untitled";
  }

  /**
   * Returns the url path of the page (if router is enabled)
   * @param {string} pageKey - key of page to get path of
   * @returns {String} - absolute path of page (example -> /project/key/page-title)
   */
  function getPagePath(pageKey = self.pageKey) {
    return Router.getPagePath(pageKey);
  }

  function preloadPage(pageKey: string) {
    self.trigger("preloadPage", pageKey);
  }

  function getPageContent(pageKey = self.pageKey) {
    return {
      key: pageKey,
      disabled: self.disabled,
      opts: storyline.getPageStory(pageKey) || self.pages[pageKey] || EMPTY_PAGE
    };
  }

  function initNextPage(pageKey) {
    self.timeline.clear();

    nextPage = getPageTag(true);
    nextPage.setVisibility(true);
    self.nextPageKey = nextPage.key = pageKey;
  }

  /**
   *
   * @param {string} toPageKey
   * @param {{inFront:boolean, mainTainScroll:boolean, toBottom:boolean, fromBottom:boolean}} transitionModel
   * @returns {PageTransition|false} - returns page transition if request was approved - false if not approved
   */
  function requestPageTransition(
    toPageKey,
    transitionModel,
    scrollTop: Number | String
  ) {
    if (scrollTop) nextPageScrollTop = scrollTop; // If the user has specified an anchor point or px scrollTop value, add to scrollQueue
    if (
      toPageKey === self.pageKey ||
      activeTransition ||
      self.timeline.isActive()
    )
      return false;
    initNextPage(toPageKey);

    const scaleValues = {
      x: WindowManager.getWidth(),
      y: WindowManager.getHeight()
    };

    self.timeline.setScaling(scaleValues);
    activeTransition = new PageTransition(self.timeline);

    fromScrollTop = WindowManager.getScrollTop();
    toScrollTop = WindowManager.getPageTop(toPageKey);

    page.transitionFrom(self.timeline, transitionModel.tweenOut, CENTER_VALUES);
    nextPage.transitionTo(
      self.timeline,
      transitionModel.tweenIn,
      CENTER_VALUES
    );

    page.pin(!transitionModel.inFront, false, fromScrollTop);
    nextPage.pin(transitionModel.inFront, false, toScrollTop);

    nextPage.setContent(getPageContent(self.nextPageKey));
    activeTransition.pause();

    return activeTransition;
  }

  function setPage(pageKey: string, scrollTop: Number | String) {
    if (self.pageKey === pageKey) return;

    if (scrollTop) nextPageScrollTop = scrollTop; // If the user has specified an anchor point or px scrollTop value, add to scrollQueue

    if (activeTransition) {
      activeTransition.destroy();
      activeTransition = false;
    }

    const currentStoryKey = storyline.getPageStoryKey(self.pageKey);
    const nextStoryKey = storyline.getPageStoryKey(pageKey);

    let prevKey = self.pageKey;
    self.nextPageKey = self.pageKey = pageKey;
    Router.set(getPageTitle(self.nextPageKey), self.nextPageKey, prevKey);

    if (!page) {
      page = getPageTag(true);
      page.setVisibility(true);
      getPageTag(true).setVisibility(false);
    }

    if (page.key !== pageKey) {
      if (currentStoryKey && currentStoryKey === nextStoryKey) {
        page.update({ key: pageKey });
      } else {
        page.setContent(getPageContent());
      }
    }

    raf(() => triggerPageChange());
    self.update();
  }

  function onTransitionStart() {
    self.trigger("pageTransitionStart", self.nextPageKey, toScrollTop);
  }

  function onTransitionComplete() {
    Router.set(getPageTitle(self.nextPageKey), self.nextPageKey, self.pageKey);

    self.pageKey = self.nextPageKey;
    page = nextPage;
    hidePage(getPageTag(true));
    cleanupTransition(toScrollTop);
    self.update();
  }

  function onTransitionReverseomplete() {
    hidePage(nextPage);
    cleanupTransition(fromScrollTop);
  }

  function hidePage(pageTag) {
    if (pageTag) {
      pageTag.setVisibility(false);
      pageTag.update(EMPTY_PAGE);
      pageTag.unPin();
    }
  }

  function cleanupTransition() {
    if (!self.pageKey) return console.error("Missing page key");

    // Making sure of right scroll position, in case of slow DOM update
    raf(function() {
      if (activeTransition) {
        activeTransition.destroy();
        activeTransition = 0;
      }

      delete self.nextPageKey;
      delete self.transition;
      page.unPin();
      WindowManager.setScrollTop(toScrollTop);

      triggerPageChange();
      self.timeline.clear();
    });
  }

  function isVisible(widgetKey: string) {
    const pageKey = getActivePageKey();
    const visibility = self.visibility[widgetKey];
    return !visibility || visibility === true || visibility[pageKey];
  }

  function triggerPageChange() {
    WindowManager.setActivePage(self.pageKey);
    if (queueWidgetScroll) {
      WindowManager.scrollToElement(queueWidgetScroll);
    } else if (nextPageScrollTop !== false) {
      if (typeof nextPageScrollTop === "string") {
        // Scroll to anchor element
        let scrollPosition = WindowManager.getElementPosition(
          nextPageScrollTop
        );
        WindowManager.setScrollTop(scrollPosition.top);
      } else {
        WindowManager.setScrollTop(nextPageScrollTop);
      }
    }
    nextPageScrollTop = false;
    queueWidgetScroll = false;
    self.trigger(
      "page",
      self.pageKey,
      WindowManager.getPageLocalScroll(self.pageKey)
    );
  }
});
