import { trackScroll } from "../analytics";
import raf from "./raf";
import riot from "riot";

/** Maximum number of screen heights scroll animations will be performed, will do jump to if higher */
const MAX_SCROLL_ANIMATE_SCREENS = 4;

const SIZES = {
  desktop: 1024,
  tablet: 600,
  mobile: 320
};
const isSmoothScrollSupported =
  "scrollBehavior" in document.documentElement.style;

function doScrolling(elementY) {
  const startingY = window.pageYOffset;

  console.log(elementY / window.innerHeight);
  if (Math.abs(elementY / window.innerHeight) > MAX_SCROLL_ANIMATE_SCREENS) {
    window.scrollTo(0, elementY + startingY);
  } else {
    const obj = {
      siteY: startingY
    };
    TweenLite.to(obj, 1, {
      siteY: startingY + elementY,
      ease: Power2.easeOut,
      onUpdate: function step() {
        window.scrollTo(0, obj.siteY);
      }
    });
  }
}

class WindowManager {
  scrollHeight = 0;
  contentHeight = 0;
  holderHeight = 0;
  scrollTop = -1;
  scrollDir = 0;
  froceUpdate = false;
  scaling: boolean | string[];
  // Used to prevent history popstate scroll
  blockNextScroll: boolean = false;

  viewWidth: number;
  viewHeight: number;

  scrollElement: HTMLDivElement;
  contentElement: HTMLDivElement;
  styleElement: HTMLStyleElement;

  devicemode = "desktop";
  zoom = 1;

  pageKey: string;
  pageProgress = {};
  pageOffset = {};
  pageHeight = {};

  sheet: CSSStyleSheet;

  constructor() {
    riot.observable(this);
    this.testElement = document.createElement("div");
    this.testElement.setAttribute(
      "style",
      "position:absolute;height:100vh;width:100vw;left:-10000px;top:-10000px;z-index:1000"
    );
    document.body.appendChild(this.testElement);

    this.styleElement = document.createElement("style");
    document.head.appendChild(this.styleElement);
    this.sheet = this.styleElement.sheet;
    this.sheet.insertRule(
      "body{-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;-moz-text-size-adjust: 100%;text-size-adjust: 100%;}",
      0
    );
    this.sheet.insertRule("html{}", 1);
    this.setScrollElement(window);

    if (false) {
      this.debugger = document.createElement("div");
      this.debugger.setAttribute(
        "style",
        "position:fixed;top:0;left:0;z-index:100000;color:green;text-shadow:0 0 1px red;width:100vw"
      );
      document.body.appendChild(this.debugger);
    }
  }

  /**
   * Html element
   * @param {HTMLDivElement} container
   */
  setScrollElement(scrollElement) {
    if (this.scrollElement) this.removeListeners();

    const doc = scrollElement.document;
    if (doc) {
      // IE don't have scroll element
      this.scrollElement = doc.scrollingElement || doc.documentElement;
    } else {
      this.scrollElement = scrollElement;
    }
  }

  start() {
    window.addEventListener("resize", this.handleResize, { passive: true });
    window.addEventListener("scroll", this.handleScroll, { passive: false });
    window.addEventListener("wheel", this.handleWheel, { passive: true });
    window.addEventListener("popstate", this.handlePopstate, { passive: true });
    this.updateSize();
  }

  stop() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("wheel", this.handleWheel);
    window.removeEventListener("popstate", this.handlePopstate);

    this.off();
  }

  getScrollElement() {
    return this.scrollElement;
  }

  setScrollTop(scrollTop, smooth: Boolean) {
    if (this.scrollTop !== scrollTop) {
      const { scrollElement } = this;
      if (smooth && isSmoothScrollSupported) {
        scrollElement.scrollTo({
          top: scrollTop,
          behavior: "smooth"
        });
      } else {
        scrollElement.scrollTop = scrollTop;
      }
    }
  }

  getScrollTop(forceUpdate) {
    if (forceUpdate && this.scrollElement) {
      this.scrollTop = this.scrollElement.scrollTop;
    }

    return this.scrollTop * this.zoom;
  }

  getElementPosition(elementId) {
    let element = document.getElementById(elementId);
    if (element) return element.getBoundingClientRect();
  }

  getDeviceMode() {
    return this.devicemode;
  }

  setDeviceMode(devicemode) {
    if (this.devicemode !== devicemode) {
      const element = this.getScrollElement();

      const classList = element.classList;
      if (this.devicemode) {
        classList.remove(this.devicemode);
      }
      classList.add(devicemode);
      this.devicemode = devicemode;

      raf(() => this.trigger("devicemode", devicemode));

      this.devicemode = devicemode;
      this.trigger("devicemode", devicemode);
    }
  }

  getViewportDim(axis: 0 | 1): number {
    const dim = axis ? "Height" : "Width";
    return this.testElement["client" + dim];
  }

  getHeight(): number {
    return this.viewHeight * this.zoom;
  }

  getWidth(): number {
    return this.viewWidth * this.zoom;
  }

  getPageHeight() {
    return this.pageHeight * this.zoom;
  }

  isFullWidth() {
    if (Array.isArray(this.scaling)) {
      return this.scaling.indexOf(this.devicemode) !== -1;
    }
    return this.scaling;
  }

  setPageStats(pageKey: string, scrollTopOffset: number, height: number) {
    this.pageProgress[pageKey] = 0;
    this.pageOffset[pageKey] = scrollTopOffset;
    this.pageHeight[pageKey] = height;
    if (this.pageKey === pageKey && this.restrict) {
      this.handleScroll();
    }
  }

  setActivePage(key: string) {
    this.pageKey = key;
    if (this.restrict) {
      this.handleScroll();
    }
  }

  getPageTop(pageKey: string = this.pageKey): number {
    return this.pageOffset[pageKey] || 0;
  }

  restrictToPage(doRestrict) {
    this.restrict = doRestrict;
    this.handleScroll();
  }

  getLastPageScroll(pageKey: string = this.pageKey): number {
    const progress = this.pageProgress[pageKey] || 0;
    const pageHeight = this.pageHeight[pageKey] || this.viewHeight;
    return this.getPageTop() + pageHeight * progress - this.viewHeight;
  }

  getPageLocalScroll(pageKey: string = this.pageKey): number {
    return this.scrollTop - this.getPageTop(pageKey);
  }

  getPageProgress(pageKey: string = this.pageKey) {
    const pageHeight = document.body.scrollHeight;
    const viewHeight = this.getHeight();
    const top = this.getPageLocalScroll(pageKey);
    return Math.max(0, Math.min(1, top / (pageHeight - viewHeight)));
  }

  scrollToPage(pageKey: string, offset: number = 0) {
    this.setScrollTop(this.getPageTop(pageKey) + offset);
  }

  scrollToTop = (duration = 1) => {
    TweenMax.to(window, duration, {
      scrollTo: this.getPageTop(this.pageKey),
      autoKill: false
    });
  };

  scrollToElement(elementId: string) {
    if (!elementId) return;
    const el = document.getElementById(elementId);
    if (el) {
      const rect = el.getBoundingClientRect();
      doScrolling(rect.top, 500);
    }
    // TweenMax.to(window, 1, { scrollTo: `#${element}`, autoKill: false });
  }

  setDisabled(disabled: boolean) {
    this.disabled = disabled;
    this.updateDeviceMode();
  }

  isScrollInRange(range: [Number, Number]) {
    return this.scrollTop > range[0] && this.scrollTop < range[1];
  }

  setBlock(blockScroll) {
    this.blockScroll = blockScroll;
  }

  isBlocked() {
    return this.blockScroll;
  }

  updateSize() {
    this.viewWidth = this.getViewportDim(0);
    this.viewHeight = this.getViewportDim(1);
    this.pageHeight = document.body.scrollHeight;
    this.updateDeviceMode();
  }

  updateDeviceMode() {

    const { viewWidth, viewHeight } = this;
    if (!this.disabled) {
      let devicemode;
      if (!devicemode) {
        if (viewWidth >= viewHeight) {
          devicemode = "desktop";
        } else if (viewWidth >= SIZES.tablet) {
          devicemode = "tablet";
        } else {
          devicemode = "mobile";
        }
      }
      this.setDeviceMode(devicemode);
    }
    const size = SIZES[this.devicemode];

    this.zoom = this.disabled ? 1 : viewWidth / size;
    const holderStyle = this.sheet.cssRules[0].style;
    const outerStyle = this.sheet.cssRules[1].style;

    let zoom = null,
      textAdjust = null;
    if (this.isFullWidth() || this.zoom < 1) {
      zoom = this.zoom;
      textAdjust = `${Math.round(this.zoom * 100)}%`;
    }

    if ("MozTransform" in holderStyle) {
      holderStyle.MozTransform = zoom ? `scale(${zoom})` : null;
      holderStyle.width = zoom ? `${100 / zoom}vw` : null;
      holderStyle.height = zoom ? `${100 / zoom}vh` : null;
      outerStyle.overflow = zoom ? "hidden" : null;
    } else {
      holderStyle.zoom = zoom;
    }

    if ("textSizeAdjust" in holderStyle)
      holderStyle.textSizeAdjust = textAdjust;
    else if ("webkitTextSizeAdjust" in holderStyle)
      holderStyle.webkitTextSizeAdjust = textAdjust;
    this.updateDebugger();
  }

  updateDebugger() {
    if (this.debugger) {
      this.debugger.innerHTML = `w:${this.viewWidth} h:${this.viewHeight} s:${
        this.scrollTop
      } p:${this.getPageProgress()} z:${this.zoom}`;
    }
  }

  handlePopstate = () => {
    this.blockNextScroll = true;
  };

  handleResize = e => {
    this.updateSize();
    this.trigger("resize", e);
  };

  handleScroll = e => {
    if (this.blockNextScroll) {
      console.log("BLOCK NEXT");
      this.blockNextScroll = false;
      return e.preventDefault();
    }

    const scrollTop = this.scrollElement.scrollTop;

    if (this.scrollTop !== scrollTop) {
      this.scrollTop = scrollTop;
      if (this.restrict) {
        const top = this.getPageTop();
        const bottom = top + this.pageHeight[this.pageKey] - this.getHeight();
        if (this.scrollTop < top || this.scrollTop > bottom) {
          this.scrollTop = this.scrollElement.scrollTop = Math.min(
            bottom,
            Math.max(top, this.scrollTop)
          );
        }
      }
      trackScroll((this.pageProgress[this.pageKey] = this.getPageProgress()));
      this.trigger("scroll", e);
    }

    this.updateDebugger();
  };

  handleWheel = e => {
    this.trigger("wheel", e);
  };
}

export default new WindowManager();
